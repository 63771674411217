.dark {
  p, span, h2, a, input, label {
    color: #fff;
  }

  li {
    background-color: #1C2D4E;
    position: relative;
  }

  input {
    background-color: #1C2D4E;
  }

  .bgMain {
    background-color: #0A0C24;
  }

  .backgroundMain {
    width: 440px;
    height: 714px;
    position: fixed;
    left: 100%;
    top: 50%;
    transform: translateY(-50%) translateX(-60%);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
    pointer-events: none;
    z-index: 0;
  }

  .bgSecond {
    background-color: #111E39;
  }

  .bgBlock {
    background-color: #1C2D4E;
    position: relative;
  }

  .bgListItem {
    background-color: #132240;
  }

  .contentText {
    background-color: #122241;
  }

  .bgLittleBtn {
    background-color: #132240;
  }

  .bgDark {
    background-color: #0C1527;
  }

  .bgGray {
    background-color: #152134;
  }

  .bg-icon {
    background-color: #1B263B;
    border-radius: 50%;
  }

  //.bgButtonLabel {
  //  background-color: #2359B8;
  //}

  .nav {
    background-color: #0C0E2C;
  }

  .currency {
    background: #4C81BA;
  }

  .circle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid #FFFFFF;
    transition: .2s;
  }

  .switch:has(input:checked) .circle {
    border: 1px solid #2359B8;
    background-color: #2359B8;
  }

  .decoration {
    height: 1px;
    width: 100%;
    display: block;
    background-color: #fff;
    opacity: 0.1;
  }

  .event {
    background-color: #152233;
    line-height: 1.2em;
    position: relative;
    color: #FE8442;

    p {
      line-height: 1.2em;
      color: #FE8442;
    }
  }

  .networkLabel {
    background-color: #2359B8;
  }

  .circleBtn {
    background-color: #FFFFFF26;
    border-radius: 50%;
  }

  .searchIcon {
    opacity: 1;
  }

  .rangeCircle {
    position: absolute;
    top: 8px;
    height: 12px;
    width: 12px;
    border: 2px solid #fff;
    border-radius: 50%;
    transform: translateY(-50%) translateX(-50%);
    background-color: #0A0C24;
    z-index: 10;

    &__0 {
      left: 0;
    }
    &__25 {
      left: 25%;
    }
    &__50 {
      left: 50%;
    }
    &__75 {
      left: 75%;
    }
    &__100 {
      left: 100%;
    }
  }

  .rangeEmpty {
    position: absolute;
    left: 0;
    top: 7px;
    width: 100%;
    height: 2px;
    background-color: #fff;
    opacity: 0.3;
    pointer-events: none;
    z-index: 5;
  }

  .rangeFull {
    position: absolute;
    left: 0;
    top: 7px;
    height: 2px;
    background-color: #fff;
    pointer-events: none;
    z-index: 6;
  }

  .thumb {
    position: absolute;
    top: 8px;
    pointer-events: none;
    height: 16px;
    width: 16px;
    border: 3px solid #fff;
    border-radius: 50%;
    transform: translateY(-50%) translateX(-50%);
    background-color: #0A0C24;
    z-index: 11;
  }

  .bottomBtn, .action {
    background-color: #2359B8;
  }

  .copyAction {
    background-color: #17294C;
  }

  .linkActive {
    background-color: #2359B8;
  }

  .boxValue {
    background-color: #182847;
  }

  .choose:has(input:checked) span {
    background-color: #2359B8;
  }

  .choose:has(input:checked) p {
    background-color: #2359B8;
  }

  .continueBtn {
    background-color: #2359B8;
    border: 1px solid #2359B8;
  }

  .cancelBtn {
    border: 1px solid #2359B8;
  }
}




.light {
  p, span, h2, a, input, label {
    color: #2C4A7E;
  }

  li {
    background-color: #fff;
  }

  input {
    background-color: #fff;
  }

  .bgMain {
    background-color: #EDF3FF;
  }

  .backgroundMain {
    display: none;
  }

  .bgSecond {
    background-color: #F4F5FA;
  }

  .bgBlock {
    background-color: #fff;
  }

  .bgListItem {
    background-color: #F4F8FF;
  }

  .contentText {
    background-color: #fff;
  }

  .bgLittleBtn {
    background-color: #82A5E2;
  }

  .bgDark {
    background-color: #fff;
  }

  .bg-icon {
    background-color: #EDF3FF;
    border-radius: 50%;
  }

  .bgGray {
    background-color: #fff;
  }

  //.bgButtonLabel {
  //  background-color: #FFF;
  //}

  .nav {
    background-color: #E6EAFF;
  }

  .currency {
    background-color: #599FEA;
  }

  .circle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid #2C4A7E;
    transition: .2s;
  }

  .switch:has(input:checked) .circle {
    border: 1px solid #2C4A7E;
    background-color: #2C4A7E;
  }

  .decoration {
    height: 1px;
    width: 100%;
    display: block;
    background-color: #2C4A7E;
    opacity: 0.1;
  }

  .event {
    background-color: #9BAFD1;
  }

  .networkLabel {
    background-color: #2C4A7E;
    color: #fff;
  }

  .circleBtn {
    background-color: #FFFFFF26;
    border-radius: 50%;
  }

  .circleBtnAcc {
    background-color: #FFFFFF26;

  }

  .searchIcon {
    opacity: 0.3;
  }

  .rangeCircle {
    position: absolute;
    top: 8px;
    height: 12px;
    width: 12px;
    border: 2px solid #fff;
    border-radius: 50%;
    transform: translateY(-50%) translateX(-50%);
    background-color: #EDF3FF;
    z-index: 10;

    &__0 {
      left: 0;
    }
    &__25 {
      left: 25%;
    }
    &__50 {
      left: 50%;
    }
    &__75 {
      left: 75%;
    }
    &__100 {
      left: 100%;
    }
  }

  .rangeEmpty {
    position: absolute;
    left: 0;
    top: 7px;
    width: 100%;
    height: 2px;
    background-color: #2C4A7E;
    opacity: 0.3;
    pointer-events: none;
    z-index: 5;
  }

  .rangeFull {
    position: absolute;
    left: 0;
    top: 7px;
    height: 2px;
    background-color: #2C4A7E;
    pointer-events: none;
    z-index: 6;
  }

  .thumb {
    position: absolute;
    top: 8px;
    pointer-events: none;
    height: 16px;
    width: 16px;
    border: 3px solid #2C4A7E;
    border-radius: 50%;
    transform: translateY(-50%) translateX(-50%);
    background-color: #EDF3FF;
    z-index: 11;
  }

  .bottomBtn, .action {
    background-color: #2C4A7E;
  }

  .copyAction {
    background-color: #2C4A7E;
  }

  .linkActive {
    background-color: #2C4A7E;
  }

  .boxValue {
    background-color: #fff;
  }

  .choose:has(input:checked) span {
    background-color: #2C4A7E;
  }

  .choose:has(input:checked) p {
    background-color: #2C4A7E;
  }

  .continueBtn {
    background-color: #2C4A7E;
    border: 1px solid #2C4A7E;
  }

  .cancelBtn {
    border: 1px solid #2C4A7E;
  }
}

.bottomBtn {
  max-width: calc(640px - 32px);
  width: calc(100% - 32px);
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  //background-color: #2C4A7E;
  position: fixed;

  span {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
  }
}

.scanner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
}

#qrCodeContainer {
  position: absolute !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 300px;
  border-radius: 7px;
  overflow: hidden;
  z-index: 7;
}

#qr-shaded-region {
  border-width: 50px !important;
}

.qr-message {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(00,0,0,0.5);
  font-size: 18px;
  color: rgb(255,255,255);
  text-align: center;
  word-break: break-all;
}

video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto !important;
  min-width: 100%;
  min-height: 100%;
  transform: translate(-50%, -50%);
}

.qr-closer {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100vh;
  height: 100vh;
  transform: translate(-50%, -50%);
  z-index: 6;
  background-color: rgba(0,0,0,0.6);

  img {
    position: fixed;
    right: calc(50% - 200px);
    top: calc(50% - 165px);
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
  }
}

.loadingWrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 8;
}

.loadingWrapper span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

input.MuiInputBase-input {
  height: 100% !important;
  padding: 0 0 0 16px !important;
}

.MuiStack-root {
  padding-top: 0 !important;
  height: 100% !important;
}

.MuiInputBase-root.MuiInputBase-formControl.MuiInputBase-adornedEnd {
  width: 100%;
  padding: 0;
  height: 100% !important;
}

.MuiFormControl-root.MuiTextField-root {
  min-width: unset !important;
  height: 100% !important;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
  display: none;
}