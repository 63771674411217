.content {
  height: calc(100vh - 260px);
  margin-top: 18px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  padding: 25px 16px 102px;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  overflow: auto;

  li {
    border-radius: 18px;
  }

  p {
    font-size: 18px;
  }

  &__item {
    border-radius: 18px;
    padding: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__itemCurrency {
    display: flex;
    column-gap: 16px;
    align-items: center;

    img {
      width: 40px;
      height: 40px;
      background-color: transparent;
    }
  }
}

.decorLine {
  height: 1px;
  width: 100%;
  background-color: #434E64;
  display: block;
  margin-bottom: 14px;
}

.event {
  padding: 0 18px 18px;

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      border-radius: 5px;
      padding: 7px 11px;

      img {
        transform: rotateZ(-90deg);
      }
    }

    p {
      font-size: 12px;
      line-height: 11px;
      opacity: 0.6;
    }
  }
}

